<template>
  <div>
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-card-text>
            <h4>
              Update Iqama
            </h4>
          </b-card-text>
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form @submit.prevent="handleSubmit(updateIqama)">
              <b-row>
                <!-- From -->
                <b-col cols="6">
                  <date-time
                    id="from"
                    :datetime.sync="iqamaData.from"
                    name="From Date"
                    rules="required"
                    :config="{ dateFormat: 'Y-m-d',minDate:'today'}"
                  />
                </b-col>

                <!-- To -->
                <b-col cols="6">
                  <date-time
                    id="to"
                    :datetime.sync="iqamaData.to"
                    name="To Date"
                    rules="required"
                    :config="{ dateFormat: 'Y-m-d',minDate:iqamaData.from}"
                  />
                </b-col>

                <!-- Prayer -->
                <b-col cols="6">
                  <custom-vue-select
                    id="prayer"
                    label="Prayer"
                    placeholder="Please choose prayer"
                    rules="required"
                    :value-reducer="option=>option.value"
                    text-field="text"
                    :options="iqamaList"
                    :selected.sync="iqamaData.iqamaName"
                  />
                </b-col>

                <!-- time -->
                <b-col
                  md="6"
                >
                  <date-time
                    id="start-time"
                    v-b-tooltip.hover
                    title="To enable time select prayer type first"
                    :datetime.sync="iqamaData.iqamaTime"
                    name="Iqama Time"
                    rules="required"
                    :disabled="!iqamaData.iqamaName"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      altInput: true,
                      altFormat: 'h:i K',
                    }"
                  />
                </b-col>

                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                  >
                    Save
                  </b-button>

                  <back />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'

import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'UpdateIqama',
  components: {
    Back,
    DateTime,
    CustomVueSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert } = handleAlerts()
    return {
      successfulOperationAlert,

      getValidationState,
    }
  },
  data() {
    return {
      iqamaData: {
        entityId: this.$store.getters['mainEntity/getEntityId'],
        from: '',
        to: '',
        iqamaTime: '',
        iqamaName: '',
      },
      iqamaList: [
        { value: 'iqamah_fajr', text: 'Fajr' },
        { value: 'iqamah_duhr', text: 'Duhr' },
        { value: 'iqamah_asr', text: 'Asr' },
        { value: 'iqamah_maghrib', text: 'Maghrib' },
        { value: 'iqamah_isha', text: 'Isha' },
      ],
    }
  },
  watch: {
    'iqamaData.iqamaName': function () {
      this.$set(this.iqamaData, 'iqamaTime', '')
    },
  },
  methods: {
    updateIqama() {
      return this.$portalUsers.put('/internalops/update-iqama', this.iqamaData).then(() => {
        this.successfulOperationAlert('Iqama time is updated successfully')
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
.form-control {
    border: 1px solid #d8d6de;
}
</style>
